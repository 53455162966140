/**
 * ForMe Server
 * 2.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
    baseUrl: "http://localhost:8081/api",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {
    localDevelopment: "http://localhost:8081/api",
    officeDevelopmentLan: "http://10.20.50.105:8081/api",
    officeDevelopmentTailscale: "http://vpn-friday.haiidev.co.kr:8081/api",
    gcpDevelopment: "https://forme.haiidev.co.kr/api",
    production: "https://forme.haii.io/api",
    localApiGateway: "http://localhost:8880/forme",
    gcpDevApiGateway: "https://api.haiidev.co.kr/forme",
    gcpProdApiGateway: "https://api.haii.io/forme",
    officeDevelopmentTailscaleApiGateway: "http://vpn-friday.haiidev.co.kr:8881/forme"
};
export type UserListRequest = {
    kidName?: string | null;
    organizations?: {
        uuid?: string;
        model?: string;
    }[] | null;
    grade?: number | null;
    "class"?: string | null;
    age?: number | null;
    planEndDateRange?: string[];
};
export type EntityListResponse = {
    total?: number;
};
export type EntityResponse = {
    UUID: string;
    CTS?: number;
    MTS?: number;
};
export type SchedulePlanResponse = EntityResponse & {
    startDate: string;
    period: number;
    endDate: string;
};
export type OrganizationResponse = EntityResponse & {
    displayName: string;
    model: string;
    firstGroup: string;
    region: string;
    address?: string;
};
export type LicenseDetail = EntityResponse & {
    lCode: string;
    sCode: string;
    version?: number;
    issuer?: string;
    model?: string;
    product?: number;
    item?: number;
    issued?: number;
    expire?: number;
    vCode?: string;
    iCode?: string;
    registered_flag?: boolean;
    delivery_flag?: boolean;
    appendix?: string;
    planStartDT?: string;
    planEndDT?: string;
    planPeriod?: number;
};
export type AdminResponse = EntityResponse & {
    displayName: string;
    email: string;
    phone?: string;
    roleLevel?: number;
    organizationName?: string;
    organization?: OrganizationResponse;
    appendix?: string;
    license?: LicenseDetail;
};
export type BillingResponse = {
    lCode: string;
    activeTS: number;
    expireTS: number;
};
export type BillingListResponse = EntityListResponse & {
    billing: BillingResponse[];
};
export type AssessmentResult = {
    assessmentDT: string;
    taskLevel: number;
};
export type UserResponse = EntityResponse & {
    exID: string;
    parentExID: string;
    nickName: string;
    fullName: string;
    taskLevels: number[];
    sttDisplayFlag: boolean;
    demoFlag: boolean;
    cyclePeriod: number;
    affiliates: number[];
    prescribedFlag: boolean;
    requiredTaskUUIDs: string[];
    appendix: string;
    licenseFlag?: boolean;
    planStartDT?: string;
    planEndDT?: string;
    latestHistoryLogCTS?: number;
    enabledFlag: boolean;
    assessmentFlag?: boolean;
    biomarkerFlag?: boolean;
    biomarkerCount?: number;
    biomarkerFinishedDTs?: string[];
    cntFlag?: boolean;
    sCode?: string;
    grade?: number;
    "class": string;
    birth: string;
    region: string;
    firstGroup: string;
    organizationName: string;
    licenseCount: number;
    plan?: SchedulePlanResponse;
    admin?: AdminResponse;
    billingList?: BillingListResponse;
    assessmentResults?: AssessmentResult[];
};
export type UserListResponse = EntityListResponse & {
    users: UserResponse[];
};
export type GenericResponse = {
    code: number;
    message: string | object;
};
export type UserRequest = {
    nickName?: string;
    demoFlag?: boolean;
    affiliates?: number[];
    lastName?: string;
    firstName?: string;
    birth?: string;
    region?: string;
    firstGroup?: string;
    organizationName?: string;
    grade?: number;
    "class"?: string;
    gender?: string;
    contactNumber?: string;
    appuserUUID?: string;
    model?: string;
    planPeriod?: number;
    entryUUID?: string;
};
export type TaskResponse = EntityResponse & {
    displayName: string;
    enabledFlag?: boolean;
    icon: string;
    defaultStartTM?: string;
    customizableFlag?: boolean;
    section?: number;
    essentialFlag?: boolean;
    drugFlag?: boolean;
    effectColors?: number[];
    flowPrefix?: string;
    requiredFlag?: boolean;
    details: string[];
    performableWeek?: number;
    alwaysPossibleFlag?: boolean;
};
export type TaskListResponse = EntityListResponse & {
    tasks: TaskResponse[];
};
export type TaskLevelResponse = EntityResponse & {
    task: TaskResponse;
    level: number;
    totalSteps: number;
    enabledFlag: boolean;
    chatbotService: number;
    entryLevel: number;
};
export type TaskLevelListResponse = EntityListResponse & {
    taskLevels: TaskLevelResponse[];
};
export type TaskFlowResponse = EntityResponse & {
    flow: string;
    icon: string;
    step: number;
    waitDuration: number;
    flowNext: string;
};
export type TaskFlowListResponse = EntityListResponse & {
    taskFlows: TaskFlowResponse[];
};
export type TaskScriptRequest = {
    flow: string;
    buddyUUID: string;
    scripts: string[];
};
export type TaskScriptBulkRequest = {
    taskScripts: TaskScriptRequest[];
};
export type BuddyResponse = EntityResponse & {
    displayName: string;
    ttsFlag: boolean;
    ttsService: number;
    ttsParam: string;
    enabledFlag: boolean;
    affiliate: number;
};
export type TaskScriptResponse = EntityResponse & {
    flow: string;
    buddy: BuddyResponse;
    script: string;
};
export type TaskScriptListResponse = EntityListResponse & {
    taskScripts: TaskScriptResponse[];
};
export type AppointmentsResponse = {
    timetableCTS: number;
    timetableWeeks: number;
    tasks: string;
    user: UserResponse;
};
export type AppointmentListResponse = EntityListResponse & {
    appointments: AppointmentsResponse[];
};
export type HistoryResponse = EntityResponse & {
    taskLevel: number;
    scheduledWeekday: number;
    scheduledStartTM: string;
    scheduledDuration: number;
    scheduledEndTM: string;
    notificationTimeGap: string;
    startTS?: number;
    alarmedAgent?: string;
    achievedFlag?: boolean;
    finishedFlag?: boolean;
    endTS?: number;
    fallbackCount: number;
    silenceCount: number;
    stt?: string;
    user?: UserResponse;
    task?: TaskResponse;
};
export type HistoryListResponse = EntityListResponse & {
    histories: HistoryResponse[];
};
export type HistoryLogResponse = EntityResponse & {
    detail: string;
    history?: HistoryResponse;
};
export type HistoryLogListResponse = EntityListResponse & {
    historyLogs: HistoryLogResponse[];
};
export type DateHistory = {
    date?: string;
    history?: HistoryResponse;
};
export type HistoryOrganizationResponse = {
    user: UserResponse;
    dateHistories: DateHistory[];
};
export type HistoryOrganizationListResponse = EntityListResponse & {
    userHistories: HistoryOrganizationResponse[];
};
export type StatisticsListRequest = {
    dateRange?: string[];
    userID?: string | null;
    organizations?: {
        uuid?: string;
        model?: string;
    }[] | null;
};
export type StatisticsResponse = {
    historyCTS: number;
    userID: string;
    scheduled: number;
    achieved: number;
    finished: number;
};
export type StatisticsListResponse = EntityListResponse & {
    statistics: StatisticsResponse[];
};
export type BuddyRequest = {
    displayName: string;
    ttsFlag: boolean;
    ttsService: number;
    ttsParam: string;
    enabledFlag: boolean;
    affiliate: number;
};
export type BuddyListResponse = EntityListResponse & {
    buddies: BuddyResponse[];
};
export type ScheduleReplicaRequestSchedule = {
    taskUUID: string;
    startTM: string;
};
export type ScheduleReplicaRequest = {
    userID: string;
    schedules: ScheduleReplicaRequestSchedule[];
};
export type OrganizationListResponse = EntityListResponse & {
    organization: OrganizationResponse[];
};
export type OrganizationStatsRequest = {
    date?: string;
    organizations?: {
        uuid?: string;
        model?: string;
    }[] | null;
};
export type OrganizationStats = EntityResponse & {
    displayName: string;
    firstGroup: string;
    region: string;
    startTS?: number;
    endTS?: number;
    registeredCount?: number;
    startCount?: number;
    useCount?: number;
    scheduled?: number;
    achieved?: number;
    finished?: number;
    sevenDaysScheduled?: number;
    sevenDaysAchieved?: number;
    sevenDaysFinished?: number;
    admin?: AdminResponse;
    license?: LicenseDetail;
};
export type OrganizationStatsResponse = EntityListResponse & {
    organization: OrganizationStats[];
};
export type OrganizationDetailStats = {
    grade: number;
    "class": string;
    registeredCount: number;
    startCount: number;
    useCount: number;
    maxCount: number;
    minCount: number;
};
export type OrganizationDetailStatsResponse = EntityListResponse & {
    organization: OrganizationDetailStats[];
};
export type AdminListResponse = EntityListResponse & {
    users: AdminResponse[];
};
export type AdminRequest = {
    displayName: string;
    email: string;
    organizationUUID: string;
    phone: string;
    roleLevel: number;
    licenseNum?: number;
    dateRange?: string[];
    planPeriod?: number;
    model: string;
    grade?: number;
    "class"?: string;
};
export type AdminListRequest = {
    adminList?: AdminRequest[];
};
export type NoticeRequest = {
    title: string;
    content: string;
    to: string;
};
export type LicenseListRequest = {
    organizations?: {
        uuid?: string;
        model?: string;
    }[] | null;
    registeredFlag?: boolean | null;
    parentName?: string | null;
    kidName?: string | null;
    planPeriod?: number | null;
};
export type LicenseResponse = {
    licenses?: LicenseDetail;
    appendix?: string;
    organizationName?: string;
    adminName?: string;
    email?: string;
};
export type LicenseListResponse = EntityListResponse & {
    licenseResponse: LicenseResponse[];
};
export type LicenseUpdateRequest = {
    parentName?: string;
    kidName?: string;
    deliveryFlag?: boolean;
};
export type AdminMessageResponse = {
    messageCTS: number;
    emotion: string;
    messageText?: string;
    messageURL: string;
    user: UserResponse;
};
export type AdminMessageListResponse = EntityListResponse & {
    message: AdminMessageResponse[];
};
export type AdminSurveyResponse = {
    surveyCTS: number;
    surveyWeeks: number;
    detail: string;
    result: string;
    user: UserResponse;
};
export type AdminSurveyListResponse = EntityListResponse & {
    survey: AdminSurveyResponse[];
};
export type AdminAssessmentResponse = {
    assessmentCTS: number;
    assessmentWeeks: number;
    detail: string;
    score: number;
    user: UserResponse;
};
export type AdminAssessmentListResponse = EntityListResponse & {
    assessment: AdminAssessmentResponse[];
};
export type CntLevel = {
    magicBallLevel: number;
    magicPotionForwardLevel: number;
    magicPotionBackwardLevel: number;
    magicGiftLevel: number;
};
export type CntJournalResponse = {
    date: string;
} & CntLevel;
export type AdminCntListResponse = EntityListResponse & {
    cnt: CntJournalResponse[];
};
export type AdminCntOrganizationResponse = CntLevel & {
    finished: number;
    weeks: number;
    user: UserResponse;
};
export type AdminCntOrganizationListResponse = EntityListResponse & {
    cnt: AdminCntOrganizationResponse[];
};
/**
 * 어린이 리스트
 */
export function v2AdminListKid(userListRequest: UserListRequest, { details, sorting, pagination }: {
    details?: boolean | null;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/kids${QS.query(QS.form({
        details,
        sorting,
        pagination
    }))}`, oazapfts.json({
        ...opts,
        method: "POST",
        body: userListRequest
    }));
}
/**
 * 약속 선택 이슈
 */
export function v2AdminListKidUnscheduled({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/kids/unscheduled${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 어린이 정보 조회
 */
export function v2AdminRetrieveKid(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/kids/${userId}`, {
        ...opts
    });
}
/**
 * 어린이 정보 수정(플랜 기간 연장)
 */
export function v2AdminUpdateKid(userId: string, userRequest: UserRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/kids/${userId}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: userRequest
    }));
}
/**
 * 어린이 정보 삭제
 */
export function v2AdminDeleteKid(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/kids/${userId}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * 과업 리스트
 */
export function v2AdminListTask(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TaskListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/admin/tasks", {
        ...opts
    });
}
/**
 * 과업 정보 조회
 */
export function v2AdminRetrieveTask(taskUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TaskResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/tasks/${taskUuid}`, {
        ...opts
    });
}
/**
 * 과업 레벨 리스트
 */
export function v2AdminListTaskLevel(taskUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TaskLevelListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/tasks/${taskUuid}/levels`, {
        ...opts
    });
}
/**
 * 과업 레벨 정보 조회
 */
export function v2AdminRetrieveTaskLevel(taskUuid: string, level: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TaskLevelResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/tasks/${taskUuid}/levels/${level}`, {
        ...opts
    });
}
/**
 * 과업 플로우 리스트
 */
export function v2AdminListTaskFlow(taskUuid: string, level: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TaskFlowListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/tasks/${taskUuid}/levels/${level}/flows`, {
        ...opts
    });
}
/**
 * 과업 플로우 정보 조회
 */
export function v2AdminRetrieveTaskFlow(taskFlowUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TaskFlowResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/taskflows/${taskFlowUuid}`, {
        ...opts
    });
}
/**
 * 과업 스크립트 생성
 */
export function v2AdminBulkTaskScript(taskScriptBulkRequest: TaskScriptBulkRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/admin/taskscripts", oazapfts.json({
        ...opts,
        method: "POST",
        body: taskScriptBulkRequest
    }));
}
/**
 * 과업 스크립트 리스트
 */
export function v2AdminListTaskScript({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TaskScriptListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/taskscripts${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 선택 약속 조회
 */
export function v2AdminScheduleTimetable({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: AppointmentListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/schedule/timetable${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 히스토리 리스트
 */
export function v2AdminListHistory({ devops, filter, sorting, pagination }: {
    devops?: boolean | null;
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: HistoryListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/histories${QS.query(QS.form({
        devops,
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 히스토리 정보 조회
 */
export function v2AdminRetrieveHistory(historyUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: HistoryResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/histories/${historyUuid}`, {
        ...opts
    });
}
/**
 * 히스토리 삭제
 */
export function v2AdminDeleteHistory(historyUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/histories/${historyUuid}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * 히스토리 로그 리스트
 */
export function v2AdminListHistoryLog(historyUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: HistoryLogListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/histories/${historyUuid}/logs`, {
        ...opts
    });
}
/**
 * 기관별 약속 진행 리스트
 */
export function v2AdminListHistoryOrganization({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: HistoryOrganizationListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/histories/organization${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 약속 진행 통계
 */
export function v2AdminListStatistics(statisticsListRequest: StatisticsListRequest, { sorting }: {
    sorting?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: StatisticsListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/statistics${QS.query(QS.form({
        sorting
    }))}`, oazapfts.json({
        ...opts,
        method: "POST",
        body: statisticsListRequest
    }));
}
/**
 * 버디 생성
 */
export function v2AdminCreateBuddy(buddyRequest: BuddyRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: BuddyResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/admin/buddies", oazapfts.json({
        ...opts,
        method: "POST",
        body: buddyRequest
    }));
}
/**
 * 버디 리스트
 */
export function v2AdminListBuddy(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: BuddyListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/admin/buddies", {
        ...opts
    });
}
/**
 * 버디 정보 조회
 */
export function v2AdminRetrieveBuddy(buddyUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: BuddyResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/buddies/${buddyUuid}`, {
        ...opts
    });
}
/**
 * 버디 변경
 */
export function v2AdminUpdateBuddy(buddyUuid: string, buddyRequest: BuddyRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: BuddyResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/buddies/${buddyUuid}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: buddyRequest
    }));
}
/**
 * 버디 삭제
 */
export function v2AdminDeleteBuddy(buddyUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/buddies/${buddyUuid}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * 챗봇 흐름을 그래프(DOT language)로 표현
 */
export function v2AdminRetrieveChatbotGraph(start: string | null, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: string;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/chatbot/graph${QS.query(QS.form({
        start
    }))}`, {
        ...opts
    });
}
/**
 * 오늘 약속 변경
 */
export function v2AdminApplySchedulesReplica(scheduleReplicaRequest: ScheduleReplicaRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/admin/schedules/replica", oazapfts.json({
        ...opts,
        method: "POST",
        body: scheduleReplicaRequest
    }));
}
/**
 * 기관 리스트
 */
export function v2AdminListOrganization({ filter }: {
    filter?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/organization${QS.query(QS.form({
        filter
    }))}`, {
        ...opts
    });
}
/**
 * 기관 정보 조회
 */
export function v2AdminOrganizationStats(organizationStatsRequest: OrganizationStatsRequest, { sorting, pagination }: {
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationStatsResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/organization/detail${QS.query(QS.form({
        sorting,
        pagination
    }))}`, oazapfts.json({
        ...opts,
        method: "POST",
        body: organizationStatsRequest
    }));
}
/**
 * 기관 세부 정보 조회
 */
export function v2AdminOrganizationDetailStats({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationDetailStatsResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/organization/detail${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 관리자 조회
 */
export function v2AdminRetrieveUser(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: AdminResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/admin/user", {
        ...opts
    });
}
/**
 * 관리자 리스트
 */
export function v2AdminListUser({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: AdminListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/users${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 관리자 생성
 */
export function v2AdminCreateUser(adminListRequest: AdminListRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/admin/users", oazapfts.json({
        ...opts,
        method: "POST",
        body: adminListRequest
    }));
}
/**
 * 관리자 수정
 */
export function v2AdminUpdateUser(adminUuid: string, adminRequest: AdminRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/users/${adminUuid}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: adminRequest
    }));
}
/**
 * 관리자 로그아웃
 */
export function v2AuthSignOut(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/admin/auth/signout.do", {
        ...opts,
        method: "POST"
    });
}
/**
 * 공지사항 푸시 알람 전송
 */
export function v2AdminPushNotice(noticeRequest: NoticeRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/admin/push/notice", oazapfts.json({
        ...opts,
        method: "POST",
        body: noticeRequest
    }));
}
/**
 * 라이센스 리스트
 */
export function v2AdminListLicense(licenseListRequest: LicenseListRequest, { sorting, pagination }: {
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicenseListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/licenses${QS.query(QS.form({
        sorting,
        pagination
    }))}`, oazapfts.json({
        ...opts,
        method: "POST",
        body: licenseListRequest
    }));
}
/**
 * 라이센스 appendix 변경
 */
export function v2AdminUpdateLicense(licenseUuid: string, licenseUpdateRequest: LicenseUpdateRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/license/${licenseUuid}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: licenseUpdateRequest
    }));
}
/**
 * 하루 일기 조회
 */
export function v2AdminListMessage({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: AdminMessageListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/message${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 행동 평가 및 학교 준비도 평가 조회
 */
export function v2AdminSurvey({ schoolFlag, filter, sorting, pagination }: {
    schoolFlag?: boolean | null;
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: AdminSurveyListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/survey${QS.query(QS.form({
        schoolFlag,
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 자기 효능감 평가 조회
 */
export function v2AdminAssessment({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: AdminAssessmentListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/assessment${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * CNT 게임 결과 조회
 */
export function v2AdminListCnt({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: AdminCntListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/cnts${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 기관별 CNT 게임 결과 조회
 */
export function v2AdminListCntOrganization({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: AdminCntOrganizationListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/cnt/organization${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 어린이 별 CNT 정보 조회
 */
export function v2AdminRetrieveCnt(userId: string, { sorting, pagination }: {
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: AdminCntListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/cnt/${userId}${QS.query(QS.form({
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 어린이 리스트 엑셀 다운로드
 */
export function v2AdminListKidExcelDownload(userListRequest: UserListRequest, { details, sorting, pagination }: {
    details?: boolean | null;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Blob;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/kids/excel.do${QS.query(QS.form({
        details,
        sorting,
        pagination
    }))}`, oazapfts.json({
        ...opts,
        method: "POST",
        body: userListRequest
    }));
}
/**
 * 약속 진행 엑셀 다운로드
 */
export function v2AdminListHistoryExcelDownload({ devops, filter, sorting, pagination }: {
    devops?: boolean | null;
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Blob;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/histories/excel.do${QS.query(QS.form({
        devops,
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 기관별 약속 진행 엑셀 다운로드
 */
export function v2AdminListHistoryOrganizationExcelDownload({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Blob;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/histories/organization/excel.do${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 선택 약속 엑셀 다운로드
 */
export function v2AdminScheduleTimeTableExcelDownload({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Blob;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/schedule/timetable/excel.do${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 하루 일기 엑셀 다운로드
 */
export function v2AdminMessageExcelDownload({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Blob;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/message/excel.do${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 행동 평가 및 학교 준비도 평가 엑셀 다운로드
 */
export function v2AdminSurveyExcelDownload({ schoolFlag, filter, sorting, pagination }: {
    schoolFlag?: boolean | null;
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Blob;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/survey/excel.do${QS.query(QS.form({
        schoolFlag,
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 자기 효능감 평가 엑셀 다운로드
 */
export function v2AdminAssessmentExcelDownload({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Blob;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/assessment/excel.do${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 기관 정보 엑셀 다운로드
 */
export function v2AdminOrganizationStatsExcelDownload(organizationStatsRequest: OrganizationStatsRequest, { sorting, pagination }: {
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Blob;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/organization/detail/excel.do${QS.query(QS.form({
        sorting,
        pagination
    }))}`, oazapfts.json({
        ...opts,
        method: "POST",
        body: organizationStatsRequest
    }));
}
/**
 * 기관 세부 정보 엑셀 다운로드
 */
export function v2AdminOrganizationDetailStatsExcelDownload({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Blob;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/organization/detail/excel.do${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 어린이 별 CNT 정보 엑셀 다운로드
 */
export function v2AdminRetrieveCntExcelDownload(userId: string, { sorting, pagination }: {
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Blob;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/cnt/${userId}/excel.do${QS.query(QS.form({
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * 기관별 CNT 게임 결과 엑셀 다운로드
 */
export function v2AdminListCntOrganizationExcelDownload({ filter, sorting, pagination }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Blob;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/admin/cnt/organization/excel.do${QS.query(QS.form({
        filter,
        sorting,
        pagination
    }))}`, {
        ...opts
    });
}
/**
 * refresh view tables
 */
export function v2ManualViewRefresh(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/manual/view/refresh", {
        ...opts
    });
}
/**
 * 개인정보 업데이트
 */
export function v2ManualPrivacyUpdate(body?: {
    version?: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/manual/privacy/update", oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
