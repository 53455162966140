/*
npm install --save js-cookie js-base64 axios

1. main.ts 에
  import HaiiExAuth from "@/plugins/haii/HaiiExAuth";
  Vue.use(HaiiExAuth);
2. $HaiiExAuth 로 전역 접근
3. 로그인 상태 관리는 서버에서 (쿠키 유효 상태로)
4. 첫 실행시 init 필요
5. mangeToken 를 통해 자동적으로 상태 관리하거나 개별적으로 컨트롤 가능
 */

import axios, { AxiosError } from "axios";
import { decode, encode } from "js-base64";
import cookie from "js-cookie";
import { VueConstructor } from "vue";

export type ServiceNameType = "alztalk" | "forme" | "tess";

export interface InitInterface {
  serviceName: ServiceNameType; // 서비스명
  isProduction: boolean;
  signInRedirectURI?: string;
}

export interface ChangePWInterface {
  oldPw: string;
  newPW: string;
}

export interface LoginInfoInterface {
  email: string;
  pw: string;
}

export type AuthorizeInterface = Pick<
  InitInterface,
  "serviceName" | "signInRedirectURI"
>;

export interface UserInfoInterface {
  banned_flag: boolean;
  birthday: string;
  closed_flag: boolean;
  displayName: string;
  email: string;
  emailVerified: boolean;
  gender: number;
  phone: string;
  phoneVerified: boolean;
  realName: string;
  realNameVerified: boolean;
  suspended_flag: boolean;
  uuid: string;
}

export class HaiiAuth {
  private static _instance: HaiiAuth;

  private currentUser = {} as UserInfoInterface;
  private $axios = axios.create({ withCredentials: true });
  private initParam = {} as InitInterface;

  private constructor() {
    return;
  }

  //싱글톤 패턴
  static get Instance(): HaiiAuth {
    return this._instance || (this._instance = new this());
  }

  // 로그인 후 현재 유저 정보
  get getCurrentUser(): null | UserInfoInterface {
    if (this.currentUser) {
      return this.currentUser;
    }
    return null;
  }

  // serverUrl
  get serverUrl(): string {
    if (this.initParam.isProduction) return "haii.io";
    return "haiidev.co.kr";
  }

  init(initParam: InitInterface): void {
    this.initParam = initParam;

    const sessionCurrentUser = sessionStorage.getItem("currentUser");
    if (sessionCurrentUser) {
      this.currentUser = JSON.parse(sessionCurrentUser);
    }
  }

  // 로그인 메서드
  // redirectURI 가 설정되지않았을 경우 원래 페이지로 리턴
  // base64 로 encode 후 redirect
  public signIn(isAdmin: boolean): void {
    const authorize: AuthorizeInterface = {
      serviceName: this.initParam.serviceName,
      signInRedirectURI:
        this.initParam.signInRedirectURI ?? window.location.href,
    };

    const queryBase64 = this.listQueryEncode(authorize);

    window.location.href = isAdmin
      ? `https://${this.serverUrl}/admin/auth/signin?ex=${queryBase64}`
      : `https://${this.serverUrl}/auth/signin?ex=${queryBase64}`;
  }

  // 로그인 후 서버에서 유저정보 호출 메서드
  // 인증 쿠키가 있다면  {code : 200, data:UserInfoInterface} 리턴
  async getUserInfo(isAdmin: boolean): Promise<UserInfoInterface | string> {
    try {
      if (isAdmin) {
        console.log("아직 개발전");
        return "";
      }
      if (!cookie.get(isAdmin ? "_aat" : "_at")) {
        return "로그인이 되지않았습니다.";
      }
      const serverUrl = `https://api.${this.serverUrl}/ex/v1/user/me`;

      const {
        data: { data },
      } = await this.$axios.get(serverUrl);

      this.currentUser = data;
      sessionStorage.setItem("currentUser", JSON.stringify(data));

      return data;
    } catch (e) {
      return e + "";
    }
  }

  // 로그아웃 메서드
  signOut(isAdmin: boolean): void {
    cookie.remove(isAdmin ? "_aat" : "_at", {
      domain:
        process.env.VUE_APP_ENV === "production"
          ? ".haii.io"
          : ".haiidev.co.kr",
    });
    location.reload();
  }

  // token 을 decoding 하여 Expire 유무 체크
  isRemainExpire(token: string): boolean {
    const payload = JSON.parse(this.listQueryDecode(token.split(".")[1])) as {
      exp: number;
    };

    const now = new Date().getTime();
    return now <= payload.exp * 1000;
  }

  // refresh token을 사용하여 access
  async resetAccessToken(isAdmin: boolean): Promise<boolean> {
    try {
      const serverUrl = isAdmin
        ? `https://auth.${this.serverUrl}/admin/refresh`
        : `https://auth.${this.serverUrl}/refresh`;

      const res = await this.$axios.get(serverUrl);

      return res.data.code === 200;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  // 비밀번호 변경 메서드
  async changePW(isAdmin: boolean, param: ChangePWInterface): Promise<boolean> {
    try {
      const cookieName = isAdmin ? "_aat" : "_at";
      if (!cookie.get(cookieName)) return false;

      const serverUrl = isAdmin
        ? `https://auth.${this.serverUrl}/admin/changepw`
        : `https://auth.${this.serverUrl}/changepw`;
      await this.$axios.post(serverUrl, {
        old: param.oldPw,
        new: param.newPW,
      });
      return true;
    } catch (e) {
      const err = e as AxiosError<any>;

      console.log("비밀번호 변경 실패-" + err.response?.data.message);
      return false;
    }
  }

  // 비밀번호 검증 메서드
  async validPW(isAdmin: boolean, pw: string): Promise<boolean> {
    try {
      const cookieName = isAdmin ? "_aat" : "_at";
      if (!cookie.get(cookieName)) return false;

      const serverUrl = isAdmin
        ? `https://auth.${this.serverUrl}/admin/checkpw`
        : `https://auth.${this.serverUrl}/checkpw`;

      await this.$axios.post(serverUrl, {
        pw,
      });
      return true;
    } catch (e) {
      const err = e as AxiosError<any>;
      const code = err.response?.data.code;
      console.log(code);
      if (code == 418) {
        console.log("잘못된 비밀번호--" + err.response?.data.message);
      } else {
        console.log("error--" + err.response?.data.message);
      }

      return false;
    }
  }

  setLocalToken() {
    const jwt =
      "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJkbiI6IuuwleywrOyYgSIsImVtIjoiY2hhbkBoYWlpLmlvIiwiZW12Ijp0cnVlLCJleHAiOjE3MzcwOTI1MTIsInN1YiI6ImIwMzg5Njc2LWU5ODAtNDMzMC04YTI5LTUxZDUxMjk3MjA4MiIsInJvbCI6WyJzdGMuMC5PV05FUiIsInRlc3MuMC5PV05FUiIsInRlc3MuMS5JTlZJVEUiLCJmb3JtZS4wLk9XTkVSIiwicGhlbm8uMC5PV05FUiIsImF6dC4wLk9XTkVSIl19.ejWROCFr-EE6R3GnVNIzQ_B33z0paEvQR-MxAnV6hs0_fX9pAP0olHnMynialdjKAbR43GnykK4HIo-zQCjzmQ";
    document.cookie = `_aat=${jwt}`;
  }

  async manageToken(isAdmin: boolean): Promise<void> {
    // // local 일 땐 사용 불가, 정확히는 프로토콜이 https 여야함
    if (window.location.protocol == "http:") {
      this.setLocalToken();
      return;
    }

    // 토큰 가져오기
    const token = cookie.get(isAdmin ? "_aat" : "_at");

    // 토큰이 없을 때
    if (!token) return this.signIn(isAdmin);

    //  프론트에서 JWT 토큰을 디코딩하여 access token Expire date 확인
    // 토큰이 있고 exp 가 남았을 경우

    if (this.isRemainExpire(token)) return;

    // 토큰 있고 exp 가 안남았을 때 -> 토큰  재 발행
    const refreshTokenState = await this.resetAccessToken(isAdmin);

    // // 정상 재 발행
    if (refreshTokenState) return;
    // //  refresh token 마저 만료 됐으면 로그아웃
    this.signOut(isAdmin);
  }

  // base64 Encode
  listQueryEncode(authorize: any): string {
    return encode(JSON.stringify(authorize));
  }

  // base64 decode
  protected listQueryDecode(value: string): string {
    return decode(value);
  }
}

declare module "vue/types/vue" {
  interface Vue {
    $HaiiExAuth: HaiiAuth;
  }
}

const HaiiExAuth = {
  install(Vue: VueConstructor): void {
    Vue.prototype.$HaiiExAuth = HaiiAuth.Instance;
  },
};

export default HaiiExAuth;
